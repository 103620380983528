import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Signup from "../views/Signup.vue";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import ResetPassword from "../views/ResetPassword.vue";
import LandingPage from "../views/LandingPage.vue";
import ServicePage from "../views/ServicePage.vue";
import ServiceView from "../views/ServiceView.vue";
import ValidationMeter from "../views/ValidationMeter.vue";
import PrivacyPolicy from "../views/PrivacyAndPolicy.vue";
// import Dashboard from "../views/Dashboard.vue";
// import SubDash from "../views/SubDash.vue";
// import IndividualValidation from "../views/IndividualValidation.vue";
// import BusinessValidation from "../views/BusinessValidation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/signup",
    name: "SignUp",
    component: Signup,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "/",
        name: "LandingPage",
        component: LandingPage,
      },
      {
        path: "/service",
        name: "ServicePage",
        component: ServicePage,
      },
      {
        path: "/service-demo",
        name: "ServiceView",
        component: ServiceView,
      },
      {
        path: "/validation-meter",
        name: "ValidationMeter",
        component: ValidationMeter,
      },
      {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
        component: PrivacyPolicy,
      },
    ],
  },
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   component: Dashboard,
  //   children: [
  //     {
  //       path: "/",
  //       name: "SubDash",
  //       component: SubDash,
  //     },
  //     {
  //       path: "/dashboard/validate-individual",
  //       name: "IndividualValidation",
  //       component: IndividualValidation,
  //     },
  //     {
  //       path: "/dashboard/business-validation",
  //       name: "BusinessValidation",
  //       component: BusinessValidation,
  //     },
  //   ],
  // },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
