<template>
  <auth-card @HandleClick="handleSubmit">
      <template slot="heading">
          <div class="auth-heading">
            <h4>Forgot Password</h4>
          </div>
        </template>
    <template>
            <v-form @submit="handleSubmit">
                <v-row>
                    <v-col>
                       <p class="para"> Enter your email address and we will send you a reset link</p>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <v-text-field class="text-input"  background-color="white" placeholder="Email*" outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template slot="button">
            <span class="btn-text">
              Done
            </span>
        </template>
  </auth-card>
</template>

<script>
import AuthCard from '@/components/AuthCard.vue'; 
export default {
    components:{
        AuthCard
    },
    methods: {
        handleSubmit(){
            this.$router.push({name: "ResetPassword"})
            console.log('submitted')
        }
    }

}
</script>

<style scoped>
    .para{
       margin-bottom: 2rem  ;
        color: #fff;
    }
</style>