<template>
<div class="wrapper">
    <div class="nav">
        <Nav :screenSize="screenSize" />
    </div>
    <div class="view-wrapper">
        <router-view />
    </div>
    <div>
        <Footer />
    </div>
</div>
</template>

<script>
import Nav from '../components/Nav.vue';
import Footer from '../components/Footer.vue';

export default {
    name: 'Home',
    components: {
        Nav,
        Footer
    },
    data() {
        return {
            screenSize: 0
        }
    },
    beforeMount() {
        window.addEventListener('scroll', this.getWindowWidth);
    },
    methods: {
      getWindowWidth() {
            this.screenSize = window.scrollY;
        },
    },
}
</script>

<style scoped>
@media(max-width: 800px) {
    .view-wrapper {
        /* margin-top: 4rem; */
    }
}
@media (max-width: 1000px) {
  .view-wrapper{
    padding: 0px !important;
  } 
}
</style>
