<template>
  <div class="wrapper">
      <div class="nav-container flex flex-center space-btw">
          <div class="logo"><img src="../assets/images/Group 28.svg" alt=""></div>
          <div class="signup-writeup">
              <slot name="auth-alternative"></slot>
          </div>
      </div>
      <div class="content-wrapper flex flex-center flex-column">
              <div class="heading">
                  <slot name="heading"></slot>
              </div>
          <v-card class="content" elevation="5">
              <div class="form flex flex-center flex-column">
                  <slot></slot>
                <v-btn @click="handleClick" class="btn"><slot name="button"></slot></v-btn>
              </div>
          </v-card>
      </div>
  </div>
</template>

<script>
export default {
    methods: {
        openSignin(){
            console.log('signin')
        },
        handleClick(){
            this.$emit('HandleClick')
        }
    }
}
</script>

<style scoped>
.wrapper{
    width: 100%;
    height: 100vh;
    /* background: url(''); */
    background: #094C82;
    overflow: hidden;
    font-family: "Metropolis";
}   

.nav-container{
    padding: 1rem 2rem;
}

.logo{
    height: 35px;
    width: 105px
}

.logo img{
    height: 100%;
    width: 100%
}

.signup-writeup{
    text-align: right;
}


.signup-writeup span {
     color: #ccc;
     text-align: right !important;
     margin-right: 7px;
     font-size: 11px;
}

.signup-writeup strong span{
    font-size: 12px;
    color: #fff;
    cursor: pointer;
}
.heading{
    text-align: center;
    padding: 0rem;
    margin-bottom: .8rem;
}
.content-wrapper{
    background: url('../assets/images/Vector.svg'), rgba(0,0,0,0);
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    height: calc(100% - 66px);
    width: 100%;

}
.content{
   background: #1E6197 !important;
    min-height: 430px;
    max-width: 530px;
    padding: 3rem;
    border-radius: 20px !important;

}
.form{
    height: 100%
}
.btn{
    width: 100%;
    padding: 1.6rem !important;
    margin-top: 1rem;
    background: #094C82 !important;
    color: #fff !important;
    font-weight: bolder;
    text-transform: capitalize;
    font-size: 14px !important;
    font-family:Georgia, 'Times New Roman', Times, serif;

}

@media(max-width: 900px) {
    .wrapper{
        overflow: scroll;
    }
    .content-wrapper{
        padding: 2rem;
        background-size: cover;
        background-position: center;
        margin: 2.5rem 0;
        /* margin-bottom: 3rem; */
    }
    .content{
        min-width: 300px !important;
        min-height: 600px !important;
        width: 100%;
        padding: 1.5rem;
    }
}

</style>