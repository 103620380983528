<template>
    <auth-card @HandleClick="handleSubmit">
        <template slot="auth-alternative">
            <span class="text-right">Already have an account?</span><strong><span @click="handleSignin">Sign In</span></strong>
        </template>
        <template slot="heading">
            <div class="auth-heading">
                <h4>Get Started</h4>
            <span>Create your OneID account</span>
            </div>
        </template>

        <template>
            <v-form @submit="handleSubmit">
                <v-row>
                    <v-col class="py-0  col-xs-12 col-md-6 col-sm-12 col-lg-6">
                        <v-text-field class="text-input"  background-color="white" placeholder="Firstname*" outlined></v-text-field>
                    </v-col>
                    <v-col class="py-0  col-xs-12 col-md-6 col-sm-12 col-lg-6">
                        <v-text-field class="text-input"  background-color="white" placeholder="Lastname*" outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-text-field class="text-input"  background-color="white" placeholder="Email Address*" outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-text-field class="text-input"  background-color="white" placeholder="Phone Number*" outlined></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <v-text-field class="text-input"  background-color="white" placeholder="Password*" outlined></v-text-field>
                        <div class="password-rules flex flex-center space-btw">
                            <span>4 characters minimium</span>
                            <span>One lowercase character</span>
                            <span>One speacial character</span>
                            <span>One uppercase character</span>
                            <span>One number</span>
                        </div>
                    </v-col>
                    <v-col cols="12" class="py-0">
                        <span class=" check flex flex-center">
                            <v-checkbox color="white" class="checkbox"></v-checkbox>
                            <span>By clicking the 'Get started' button, you are creating a oneID account and you agree to oneID’s <a class="terms-btn" href="#">Terms of Use</a> and <a class="terms-btn" href="#">Privacy Policy.</a></span>
                        </span>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template slot="button">
            <span class="btn-text">
                Get Started
            </span>
        </template>
    </auth-card>
</template>

<script>
import AuthCard from '@/components/AuthCard.vue';
export default {
    components: {
        AuthCard,
    },

    methods:{
        handleSignin(){
            this.$router.push('/login')
            console.log('submitted')
        },
        handleSubmit(){
            console.log('signed up')
        }
    }

}
</script>

<style>
.text-input{
    margin-bottom: -13px !important;
}
.password-rules span{
    font-size: 8px;
    padding: .2rem;
    color: rgba(255, 255, 255, 0.864);
    border-radius: 3px;
}
.password-rules span:first-child{
    background-color: #2E74AC;
}
.check{
    color: rgba(255, 255, 255, 0.864);
    font-size: 11px;
}
.terms-btn{
    color: #22B98F !important;
    text-decoration: none;
}
.checkbox{
}
</style>