import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    VERIFICATION_DIALOG: false,
    VERIFICATIONINFO_DIALOG: false,
  },
  getters: {
    GET_VERIFICATION_DIALOG(state) {
      return state.VERIFICATION_DIALOG;
    },
    GET_VERIFICATIONINFO_DIALOG(state) {
      return state.VERIFICATIONINFO_DIALOG;
    },
  },
  mutations: {
    TOGGLE_VERIFICATION_DIALOG(state) {
      state.VERIFICATION_DIALOG = !state.VERIFICATION_DIALOG;
    },
    TOGGLE_INFOVERIFICATION_DIALOG(state) {
      state.VERIFICATIONINFO_DIALOG = !state.VERIFICATIONINFO_DIALOG;
    },
  },
  actions: {},
  modules: {},
});
