<template>
  <div class="">
    <div class="footer-content wrapper">
      <div class="logo">
        <img src="../assets/images/Group 28.svg" alt="" />
        <p class="mt-4">
          OneID is an identity management and verification company, We help
          individuals, startups, software developers and institutions (private &
          public) to automate their identity verifications processes via Api
          integrations eg, Bank Verification Number (BVN), National Identity
          Number (NIN), company RC & TIN number, State TIN Number (cross River,
          Anambra, Imo, Abia, Enugu states), Vehicle identification number(VIN),
          Bank Account Number(NUBAN), driver’s license, voters card, Facial
          biometrics etc.
        </p>
      </div>
      <div class="links">
        <ul class="flex flex-column space-btw">
          <li @click="enterRoute('/')"><a href="#">Home</a></li>
          <li @click="enterRoute('/service')"><a href="#">Service</a></li>
          <li @click="enterRoute('')"><a href="#">Features</a></li>
          <li @click="enterRoute('/api')"><a href="#">APIs</a></li>
          <li @click="enterRoute('/privacy-policy')">
            <a href="#">Privacy & Policy</a>
          </li>
        </ul>
      </div>
      <div class="contact">
        <strong>Contact</strong><br />
        <span>Email - info@oneid.com</span>
        <div class="icons">
          <!-- <ul>
            <li><i class="fa fa-facebook"></i></li>
            <li><i class="fa fa-facebook"></i></li>
            <li><i class="fa fa-facebook"></i></li>
          </ul> -->
        </div>
      </div>
      <div class="subscribe">
        <strong>Subscribe</strong>
        <p>Get updates on our products, services and the industry</p>
        <div class="input">
          <input type="text" />
          <v-btn class="input-btn">Email</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    enterRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
a {
  text-decoration: none;
  color: #fff !important;
}
.wrapper {
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  background: #21283c;
  grid-gap: 25px;
  color: #ccc;
  padding: 4rem;
  font-size: 13px;
}
.links {
  text-align: center;
}
.links ul {
  list-style: none;
  align-items: space-between;
  height: 70%;
}
.logo img {
  height: 100px;
  width: 150px;
}
.links ul li {
  margin: 0 1rem;
  margin-bottom: 0.4rem;
  cursor: pointer;
}
.contact span {
  display: inline-block;
}
.icons ul {
  list-style: none;
}
.contact strong,
.subscribe strong {
  font-weight: bolder;
  font-size: 15px;
  color: #fff;
  margin-bottom: 1rem;
  display: inline-block;
}
.input input {
  background: #343a4a;
  padding: 0.7rem;
  margin-right: 0.5rem;
  border-radius: 4px;
  border: none;
  outline: none;
  color: #fff;
}
.input-btn {
  padding: 1.3rem !important;
  background: #094c82 !important;
  color: #fff !important;
}

@media (max-width: 900px) {
  .wrapper {
    grid-template-columns: 1fr 1fr;
    text-align: center;
  }
  .links ul {
    margin: 0;
    padding: 0;
  }
  .links ul li {
    width: 100%;
    text-align: center;
  }
  .logo,
  .links {
    margin-bottom: 20px;
  }
}
@media (max-width: 700px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}
</style>
