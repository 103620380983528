<template>
  <auth-card @HandleClick="handleSubmit">
      <template slot="heading">
          <div class="auth-heading">
            <h4>Reset Password</h4>
            <span>Enter your new password</span>
          </div>
        </template>
    <template>
            <v-form @submit="handleSubmit">
                <v-row>
                    <v-col class="py-0" cols="12">
                        <v-text-field class="text-input"  background-color="white" placeholder="New Password*" outlined></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <v-text-field class="text-input"  background-color="white" placeholder="Confirm Password*" outlined></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template slot="button">
            <span class="btn-text">
              Done
            </span>
        </template>
  </auth-card>
</template>

<script>
import AuthCard from '@/components/AuthCard.vue'; 
export default {
    components:{
        AuthCard
    },

    methods: {
        handleSubmit(){
            console.log('submitted')
        }
    }

}
</script>

<style scoped>
    .para{
       margin-bottom: 2rem  ;
        color: #fff;
    }
</style>