<template>
  <div :class="['nav-container', { 'is-sticky': screenSize > 0 }]">
    <div class="logo flex space-btw">
      <a href="/">
        <img src="../assets/images/Group 28.svg" alt="" />
      </a>
      <span class="bars">
        <img
          @click="showSidebar"
          id="open"
          class="open"
          v-if="showResButton"
          src="../assets/images/eva_menu-outline.png"
          alt=""
        />
        <img
          id="close"
          class="close"
          v-else
          @click="hideSidebar"
          alt=""
          src="../assets/images/Vector (1).png"
        />
      </span>
    </div>
    <div id="nav-links" class="nav-links flex flex-center" style="gap: 40px">
      <span class="text-center nav-link">
        <span class="link home" @click="enterRoute('/')">Home</span><br />
        <img v-if="route == '/'" src="../assets/images/Ellipse 5.png" alt="" />
      </span>
      <span class="text-center nav-link">
        <span class="link" @click="enterRoute('/service')">Services</span><br />
        <img
          v-if="route == '/service' || route == '/service-demo'"
          src="../assets/images/Ellipse 5.png"
          alt=""
        />
      </span>

      <span class="text-center nav-link">
        <span class="link" @click="enterRoute('/validation-meter')"
          >Validation Meter</span
        ><br />
        <img
          v-if="route == '/validation-meter'"
          src="../assets/images/Ellipse 5.png"
          alt=""
        />
      </span>
      <span class="text-center nav-link">
        <span class="link" @click="enterRoute('/apis')">APIs</span><br />
        <!-- <img  src="../assets/images/Ellipse 5.png" alt=""> -->
      </span>
      <span v-if="screenSize >= 2">
        <span class="link get-started" @click="enterRoute('/signup')"
          >Get Started</span
        >
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["screenSize"],
  data() {
    return {
      showResButton: true,
    };
  },

  created() {
    // console.log(this.$route)
  },
  computed: {
    route() {
      return this.$route.path;
    },
  },
  methods: {
    enterRoute(route) {
      this.$router.replace(route);
    },
    showSidebar() {
      this.showResButton = !this.showResButton;
      document.getElementById("nav-links").style.transform = `translateX(0)`;
    },
    hideSidebar() {
      this.showResButton = !this.showResButton;
      document.getElementById(
        "nav-links"
      ).style.transform = `translateX(-${100}%)`;
    },
  },
};
</script>

<style scoped>
.nav-container {
  padding: 1.2rem 4rem;
  background: #094c82;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
}
.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;
}

.logo img {
  height: 40px;
  width: 109px;
}

.nav-links {
  padding-right: 2rem;
  flex-direction: row;
}

.open,
.close {
  display: none;
}

.link {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.get-started {
  background-color: #fff;
  color: #094c82;
  width: 150px;
  text-align: center;
  padding: 1rem;
  border-radius: 14px;
}

.link {
  cursor: pointer;
}

.show {
  transform: translateX(100%);
}

.nav-link {
  position: relative;
}

.nav-link > img {
  position: absolute;
  bottom: -10;
  left: 40;
}

@media (max-width: 1200px) {
  .nav-container {
    grid-template-columns: 1fr 2fr;
  }
}

@media (max-width: 800px) {
  .nav-container {
    padding: 1.2rem !important;
    grid-template-columns: 1fr;
    position: fixed;
    width: 100vw;
    z-index: 1000;
  }

  .open,
  .close {
    display: block;
  }

  .open {
    height: 30px !important;
    width: 30px !important;
  }

  .close {
    height: 20px !important;
    width: 20px !important;
  }

  .nav-links {
    position: fixed;
    font-size: 12px;
    top: 75px;
    left: 0;
    height: 100vh;
    width: 70%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column !important;
    background: #044f8d;
    transform: translateX(-100%);
    padding: 3rem;
    transition: 0.7s all ease-in;
  }

  .nav-link {
    margin-bottom: 1.5rem;
  }

  .close:active .nav-links {
    transform: translateX(100%);
  }

  .logo img {
    height: 30px;
    width: 70px;
  }
}

/* 

*{
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  box-sizing: border-box;
}
body{
  font-family: montserrat;
}
nav{
  background: #0082e6;
  height: 80px;
  width: 100%;
}
label.logox{
  color: white;
  font-size: 35px;
  line-height: 80px;
  padding: 0 100px;
  font-weight: bold;
}
nav ul{
  float: right;
  margin-right: 20px;
}
nav ul li{
  display: inline-block;
  line-height: 80px;
  margin: 0 5px;
}
nav ul li a{
  color: white;
  font-size: 17px;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
}
a.activex,a:hover{
  background: #1b9bff;
  transition: .5s;
}
.checkbtn{
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}
#check{
  display: none;
}
@media (max-width: 952px){
  label.logox{
    font-size: 30px;
    padding-left: 50px;
  }
  nav ul li a{
    font-size: 16px;
  }
}
@media (max-width: 858px){
  .checkbtn{
    display: block;
  }
  ul{
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #2c3e50;
    top: 80px;
    left: -100%;
    text-align: center;
    transition: all .5s;
  }
  nav ul li{
    display: block;
    margin: 50px 0;
    line-height: 30px;
  }
  nav ul li a{
    font-size: 20px;
  }
  a:hover,a.activex{
    background: none;
    color: #0082e6;
  }
  #check:checked ~ ul{
    left: 0;
  }
}
section{
  background: url(https://i.ibb.co/sCfGc03/pexels-photo-4245826.jpg) no-repeat;
  background-size: cover;
  height: calc(100vh - 80px);
}

*/
</style>
