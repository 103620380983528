<template>
<div>
    <v-dialog v-model="VERIFICATIONINFO_DIALOG" max-width="700" min-height="700" persistent>
        <template>
            <v-card class="card-container">
                <div class="flex heading">
                    <img class="mr-4" src="../../../assets/images/pngroyale 1.png" alt="">
                    <h3 class="flex flex-center heading-text">National Identification Number Details <span class="mr-4">(Test Mode)</span></h3>
                </div>
                <div class="body">
                    <div class="text-center"> 
                        <h3>This is a test mode</h3>
                    <img width="150" height="150" src="../../../assets/images/Rectangle 59.svg" alt="">
                    </div>
                    <div>
                        <div v-for="(info, i) in verificationInfo" :key="i">
                            <label for=""><strong>{{info.lable}}</strong></label>
                            <v-text-field disabled v-model="info.value"  outlined></v-text-field>
                        </div>
                    </div>
                    
                    <div class="btn-wrap text-center">
                        <v-btn class="btn-validate" @click="validate">Ok</v-btn>
                    </div>
                </div>
            </v-card>
        </template>
    </v-dialog>
</div>
</template>

<script>
import { mapState, mapMutations} from 'vuex';
export default {
    data(){
        return{
            verificationInfo: [
                {
                    lable: 'NIN',
                    value: '238928392839'
                },
                {
                    lable: 'Firstname',
                    value: 'Precious'
                },
                {
                    lable: 'Lastname',
                    value: 'Josiah'
                },
                {
                    lable: 'Date of Birth',
                    value: '09/23/2000'
                },
                {
                    lable: 'Registration Date',
                    value: '01/2/1998'
                },
                {
                    lable: 'Phone Number',
                    value: '0908829823'
                },
                {
                    lable: 'Gender',
                    value: 'Female'
                },
            ]
        }
    },
    computed:{
        ...mapState(['VERIFICATIONINFO_DIALOG'])
    },
    methods:{
        validate(){
            this.$store.commit('TOGGLE_INFOVERIFICATION_DIALOG');
        },  
        ...mapMutations(['TOGGLE_INFOVERIFICATION_DIALOG'])
    }

}
</script>

<style scoped>
.heading{
    background: #F6F7F9;
    padding: 1rem;
}
.heading img{
    height: 50px;
    width: 50px
}

.card-container{
    border-radius: 15px !important;
}
.heading-text{
    font-weight: bolder;
    font-size: 20px;

}
.heading-text span{
    color: #E78249;
}
.body{
    padding: 3rem 4.5rem;
    text-align: left;
}
.btn-validate{
    background: #094C82 !important;
    color: #fff !important;
    font-weight: bold;
    margin-right: .5rem;
    width: 200px !important;    
    height: 47px !important;
}
.card-container::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.card-container::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

.card-container::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #D62929;
}

</style>
