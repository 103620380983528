import { render, staticRenderFns } from "./VerificationModal.vue?vue&type=template&id=410863f4&scoped=true&"
import script from "./VerificationModal.vue?vue&type=script&lang=js&"
export * from "./VerificationModal.vue?vue&type=script&lang=js&"
import style0 from "./VerificationModal.vue?vue&type=style&index=0&id=410863f4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "410863f4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCard,VCheckbox,VDialog,VTextField})
