<template>
  <div class="">
    <show-case>
      <div class="content-wrapper flex flex-center">
        <div class="content grid">
          <div class="writeup flex flex-center flex-column">
            <div style="font-weight: bolder; font-size: 52px; color: white">
              Create, Verify, Validate,<br />Obtain One Identity
            </div>
            <p style="font-size: 20px; margin: 14px 0">
              For banks, Embassy, Social media platforms, Start-ups, developers,
              individual and coporate institutions, OneID is an all-in-one
              identity verification, fraud prevention and compliance platform.
              All fully verified OneID is notorized by authorized notory public.
            </p>
            <div class="btn-wrapper">
              <v-btn class="btn-show btn-show-1" @click="enterRoute('/signup')"
                >Get Started</v-btn
              >
              <v-btn class="btn-show btn-show-2" @click="enterRoute('/login')"
                >Sign In</v-btn
              >
            </div>
          </div>
          <div class="img">
            <img src="../assets/images/Group 92.svg" alt="" />
          </div>
          <div class="arrow-down">
            <a href="#container">
              <img src="../assets/images/Frame 5.svg" alt="" />
            </a>
          </div>
        </div>
      </div>
    </show-case>
    <div class="body-content">
      <div id="container" class="container">
        <div class="whoweare grid">
          <div class="whoweare-writeup elevation-3">
            <h4>Who we are</h4>
            <p style="font-size: 14px">
              OneID is an identity management and verification company, We help
              individuals, startups, software developers and institutions
              (private & public) to automate their identity verifications
              processes via Api integrations eg, Bank Verification Number (BVN),
              National Identity Number (NIN), company RC & TIN number, State TIN
              Number (cross River, Anambra, Imo, Abia, Enugu states), Vehicle
              identification number(VIN), Bank Account Number(NUBAN), driver’s
              license, voters card, Facial biometrics etc.
            </p>
            <p style="font-size: 14px">
              OneID will help you identify the right customers and fight fraud
              using verified data. We are reliable, simple and flexible, and our
              systems are designed to blend into your workflow and optimize
              business success. With our simple Api integrations, you can be up
              and running in less than 20min.
            </p>
            <p style="font-size: 14px">
              At OneID, we ensure strict adherence to relevant industry
              standards, polices and guidelines which include NITDA NDPR rules.
              Our commitment to our clients are backed by rigorous assessments
              and our staff are trained on privacy regulations.
            </p>
            <v-btn color="#094C82" class="btn-verify">
              Start verifying identify
            </v-btn>
          </div>
          <div class="whoweare-img">
            <img src="../assets/images/landpage1.png" alt="" />
          </div>
        </div>

        <div class="features-wrap grid">
          <div class="img-wrap">
            <img src="../assets/images/landpage2.png" alt="" />
          </div>
          <div class="features">
            <v-card class="flex flex-center flex-column card-feature">
              <div>
                <img src="../assets/images/bank-svgrepo-com 1.svg" alt="" />
              </div>
              <strong>For Banks</strong>
              <p style="font-size: 14px">
                Meet Anti Money Laundry and KYC regulatory compliance, verify
                customer identity with AI powered biometric recognition systems
                and minimize time and cost of user onboarding while
                significantly increasing the volume of quality customers you
                deal with.
              </p>
            </v-card>
            <v-card class="flex flex-center flex-column card-feature">
              <div>
                <img
                  src="../assets/images/startup-rocket-svgrepo-com 1.svg"
                  alt=""
                />
              </div>
              <strong>For Startups</strong>
              <p style="font-size: 14px">
                Protect your business from fraud, build a reputable and
                trustworthy brand, build customer loyalty, cost efficiency,
                avoid doing business with identity frauds and impersonators,
                ensure you are doing business with clients or customers who are
                exactly who they say they are
              </p>
            </v-card>
            <v-card class="flex flex-center flex-column card-feature">
              <div>
                <img
                  src="../assets/images/tools-and-utensils-code-svgrepo-com 1.svg"
                  alt=""
                />
              </div>
              <strong>For Developers</strong>
              <p style="font-size: 14px">
                Easy and trusted integration to identity verification API,
                develop systems that fulfil all security , local and
                international regulations and clients needs. Extra convenient
                with sample codes and testing sandbox, simplified API response
                and codeless integration.
              </p>
            </v-card>
            <v-card class="flex flex-center flex-column card-feature">
              <div>
                <img
                  src="../assets/images/business-card-id-card-svgrepo-com 1.svg"
                  alt=""
                />
              </div>
              <strong>For Other Institutions</strong>
              <p style="font-size: 14px">
                Protection against fraud, comply with regulatory compliance,
                maintain a verified database of clients and customers, build and
                maintain a trusted business, reduction in risks and cost of
                running business.
              </p>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <div class="client-wrap">
      <div cycle height="400" hide-delimiter-background show-arrows-on-hover>
        <div>
          <h2 class="mb-7" style="text-align: center">
            Our trusted data sources
          </h2>
          <carousel
            :autoplay="true"
            :nav="false"
            :items="5"
            :responsive="responsive"
          >
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/myid 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img
                src="../assets/images/Federal-Road-Safety-Corps-FRSC-Logo.svg"
                alt=""
              />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/pngroyale 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/nibss.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/FIRS-LOGO 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img
                src="../assets/images/Federal-Road-Safety-Corps-FRSC-Logo.svg"
                alt=""
              />
            </v-card>
          </carousel>
        </div>
      </div>
    </div>
    <div class="adhere-wrap">
      <div class="adhere px-12">
        <div class="adhere-content flex flex-center flex-column">
          <h3>
            We adhere to the highest Legal Standards <br />
            everywhere we operate.
          </h3>
          <v-btn class="btn-adhere" @click="enterRoute('/signup')" color="#fff"
            >Get started</v-btn
          >
        </div>
      </div>
    </div>

    <div class="client-wrap">
      <div cycle height="400" hide-delimiter-background show-arrows-on-hover>
        <div>
          <h2 class="mb-7" style="text-align: center">
            Our trusted data sources
          </h2>
          <carousel
            :autoplay="true"
            :nav="false"
            :items="4"
            :responsive="responsive"
          >
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/Anambra 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/interswitch 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/Group 30.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/Anambra 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/ub 1.svg" alt="" />
            </v-card>
            <v-card class="carousel-item flex flex-center">
              <img src="../assets/images/verypaylogo.svg" alt="" />
            </v-card>
          </carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from "owl-carousel-vuejs";
import ShowCase from "@/components/View/ShowCase.vue";

export default {
  components: {
    ShowCase,
    Carousel,
  },
  data() {
    return {
      responsive: {
        0: { items: 1, nav: false },
        300: { items: 2, nav: false },
        600: { items: 3 },
        900: { items: 4 },
        1100: { items: 5 },
      },
      options: {
        loop: true,
        autoplay: true,
        responsive: null,
      },
    };
  },
  methods: {
    enterRoute(route) {
      this.$router.push(`${route}`);
    },
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.arrow-down {
  animation: 1.3s ease-in 2s infinite slidein;
  transform: translateY(-30%);
  opacity: 0;
}

@keyframes slidein {
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-gap: 30px;
}

.content-wrapper {
  background: url("../assets/images/Vector2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: flex-start;
  padding: 4rem;
  font-family: "Metropolis";
}

.content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-content: center;
  height: 100%;
  position: relative;
  /* border: 1px solid red; */
}

.img {
  height: 600px;
}

.img > img {
  height: 100%;
  width: 100%;
}

.img-wrap img {
  height: 100%;
  width: 100%;
}

.writeup {
  align-items: flex-start;
  padding: 2rem 0;
}

.writeup h3 {
  color: #fff;
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 1rem;
}

.writeup p {
  color: #fff;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.btn-show {
  height: 50px !important;
  width: 190px;
  color: #fff;
  border-radius: 7px;
}

.btn-show-1 {
  color: #094c82;
  margin-right: 15px;
}

.btn-show-2 {
  border: 1px solid #fff;
  background-color: transparent !important;
}

.arrow-down {
  height: 70px;
  width: 70px;
  position: absolute;
  bottom: 0px;
  right: 50%;
  cursor: pointer;
  /* transform: translateX(-50%); */
}

.arrow-down img {
  height: 100%;
  width: 100%;
}

.body-content {
  background: #f9f9f9;
  padding: 4rem;
}

.whoweare-writeup h4 {
  color: #094c82;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.whoweare-writeup {
  padding: 3rem;
  border-radius: 10px;
}

.whoweare-writeup p {
  font-size: 15px;
}

.whoweare-img {
  padding: 3rem;
}

.whoweare-img img {
  width: 100%;
  height: 100%;
}

.features-wrap {
  margin-top: 4rem;
  /* height: 500px; */
}

.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

.features > div {
  padding: 1rem;
  font-size: 0.8rem;
}

.features > div > strong {
  text-align: center;
}

.btn-verify {
  padding: 0.8rem !important;
  color: #fff;
}

.img-wrap {
  padding: 3rem;
  height: 100%;
  width: 100%;
}

.card-feature {
  text-align: center;
  padding: 1.5rem;
}

.card-feature div {
  width: 50px;
  height: 50px;
  margin: 0 auto;
  margin-bottom: 1rem;
}

.card-feature div > img {
  height: 100%;
  width: 100%;
}

.card-feature > strong {
  font-size: 1rem;
  display: inline-block;
  font-weight: bolder;
  color: #000;
  margin-bottom: 1rem;
}

.client-wrap {
  background: #f4f4f4;
  padding: 2.5rem;
}

.carousel-item {
  height: 170px;
  width: 170px;
  margin: 0 1rem;
  padding: 1.5rem;
  text-align: center;
}

.carousel-item img {
  height: 100%;
  width: 90px;
  display: inline-block;
}
.adhere {
  background: #094c82;
}
.adhere-wrap {
  padding: 2.5rem 0;
}
.adhere-content {
  background: url("../assets/images/ion_finger-print-sharp.svg");
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  color: #fff;
  height: 200px;
  font-size: 1.1rem;
  text-align: center;
}
.btn-adhere {
  margin-top: 1rem;
  color: #094c82;
  font-weight: bolder;
  padding: 1.5rem 2rem !important;
}
@media (max-width: 800px) {
  .writeup {
    padding-bottom: 0px;
  }
  .btn-show-1,
  .btn-show-2 {
    width: 150px;
  }
  .writeup h3 {
    font-size: 1.7rem;
  }
  .content-wrapper {
    padding-top: 70px;
  }
  .content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
  .img {
    height: 300px;
    width: 100%;
    text-align: center;
  }
  .img img {
    height: 100%;
    width: 300px;
  }
  .features {
    grid-template-columns: 1fr;
    order: 1;
  }
  .img-wrap {
    order: 2;
  }
  .arrow-down {
    display: none;
    height: 60px;
    width: 40px;
    bottom: -70px;
    left: 50;
  }
  .body-content {
    padding: 1.5rem;
  }
}
</style>
