<template>
  <auth-card @HandleClick="handleSubmit">
      <template slot="heading">
          <div class="auth-heading">
            <h4>Login</h4>
            <span>Provide your login details</span>
          </div>
        </template>
        <template slot="auth-alternative">
            <span>New User?</span><strong><span @click="handleSignup">Sign Up</span></strong>
        </template>
    <template>
            <v-form @submit="handleSubmit">
                <v-row>
                    <v-col class="py-0" cols="12">
                        <v-text-field class="text-input"  background-color="white" placeholder="Firstname*" outlined></v-text-field>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <v-text-field class="text-input"  background-color="white" placeholder="Lastname*" outlined></v-text-field>
                    </v-col>
                    <v-col class="py-0">
                        <span @click="handleForgotPassword" class="forgot-pass">Forgot password?</span>
                    </v-col>
                </v-row>
            </v-form>
        </template>
        <template slot="button">
            <span class="btn-text">
               Sign In
            </span>
        </template>
  </auth-card>
</template>

<script>
import AuthCard from '@/components/AuthCard.vue'; 
export default {
    components: {
        AuthCard,
    },
    methods:{
        handleSignup(){
            this.$router.push('/signup')
            console.log('signed up')
        },
        handleForgotPassword(){
            this.$router.push({name: 'ForgotPassword'})
            console.log('Password forgot')
        },
        handleSubmit(){
            console.log('Submitted')
        }
    }
}
</script>

<style>
.forgot-pass{
    color: #fff;
    cursor: pointer;
}
</style>