<template>
    <div class="wrapper">
        <div class="content-wrapper flex flex-center flex-column" >
            <slot></slot>
        </div>
    </div>
</template>

<script>

export default {
    props: ['backgroundUrl']

}
</script>
<style scoped>

 .wrapper{
     height: calc(100vh - 95px);
     width: 100%;
    background: #094C82;

 }
 .content-wrapper {
     height: 100%;
 }
@media (max-width: 800px) {
    .wrapper{
        height: 100vh;
    }
}
</style>