<template>
<div>
    <show-case>
        <div class="content-wrapper flex flex-center flex-column text-center">
            <h1>Validation Meter</h1>
            <p>OneID has created a meter-based assessment to assign rating on any registered user in the OneID system.</p>
            <button class="btn-showcase" @click="openService">Validate your identity</button>
        </div>
    </show-case>
    <div class="body">
        <v-row class="body-content">
            <v-col class="col-md-6 col-sm-12">
                <div class="pr-5">
                    <p>
                        The rating is a function of the number of different verifications any user was able to validate using different parameters like BVN, NIN, Facial Biometrics matched to your NIN enrollment picture, valid residential address, Driver’s license, Nigerian International Passport etc. Each of the Identity category carries a Mark which adds to your Validation Meter. The higher your meter, the more the value of your identity.
                        Different institutions that may use OneID to verify the identity of their clients can choose any validation benchmark, that gives them comfort, as their yardstick to measure how valid an individual is.
                    </p>
                    <p>
                        Every institution that desire to use this service to validate their clients will require a single API integration with OneID. The API will enable them to integrate the service in their core application and will be able to retrieve the required data of the applicable OneID registered user.
                        By subscribing to this service, the Institution will save cost and the stress of doing separate independent verification(s) e.g Address verification, live photograph, NIN verification, BVN validation, NUBAN validation etc.
                    </p>
                </div>
            </v-col>
            <v-col class="col-md-6 col-sm-12">
               <div class="img-container">
                    <img src="../assets/images/Group 126.png" alt="">
               </div>
            </v-col>
        </v-row>
    </div>
</div>
</template>

<script>
import ShowCase from '../components/View/ShowCase.vue';
export default {
    components: {
        ShowCase
    },
    methods:{
        openService(){
            this.$router.push('/signup')
        }
    }

}
</script>

<style scoped>
.content-wrapper{
    color: #fff;
    background: url('../assets/images/Rectangle 12.png');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}
    .body-content{
        margin: 2rem 0;
        padding:4rem;
    }
    img{
        max-height: 100%;
        width: 100%
    }
    .img-container{
        height: 400px;
        
    }
    .btn-showcase{
        padding: 1rem;
        border-radius: 5px;
        background-color: #fff;
        color: #094C82
    }
    .btn-showcase:active{
        transform: scale(.9);
    }
</style>
