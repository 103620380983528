<template>
<div class="wrapper">
  <div class="grid-item"></div>
    <div class="service-writeup flex flex-center flex-column">
        <div class="writpup">
            <h3 class="mb-3">
                <slot name="heading"></slot>
            </h3>
            <p class="caption">
                <slot name="caption"></slot>
            </p>
            <div>
                <slot></slot>
            </div>
            <v-btn @click="demo" class="btn-demo mt-4">
                Demo
            </v-btn>
        </div>
    </div>
    <div class="img-flex">
        <div class="img-wrapper" :style="{background: background}">
            <div class="img">
                <slot name="image">

                </slot>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import CheckWrite from './CheckWriteup.vue'
export default {
  props: ['background'],
    components: {
        CheckWrite
    },
    data() {
        return {

        }
    },
    methods: {
      demo(){
         this.$emit('handledemo');
         console.log('demo')
      }
    }
}
</script>

<style scoped>
.wrapper {
    display: grid;
    grid-template-columns: 1fr 3fr 3fr;
    place-items: center;
    place-content: center;
    margin-bottom: 5rem;
}

.writpup{
  width: 100%
}

.img-wrapper {
   width: 330px;
    height: 330px;
    position: relative;
}

.caption{
    font-size: 13px;
}

.service-writeup {
    width: 60%;
    
}

.btn-demo {
    height: 40px;
    width: 65px;
    color: #094C82 !important;
    font-weight: bolder;
    background: #fff !important;
}

.img {
     width: 300px;
    height: 300px;
    position: absolute;
    top: 30px;
    left: -100px
}

.img-flex {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}

@media (max-width: 1000px) {
  .grid-item{
    display: none
  }
  .wrapper{
    grid-template-columns: 1fr 1fr;
  } 
}
@media (max-width: 740px) {
  .grid-item{
    display: none
  }
  .wrapper{
    grid-template-columns: 1fr;
    place-content:left;
    
  } 
  .img-flex{
    display: flex;
      align-items: center;
      justify-content: flex-start;
      order: 1;
  }
  .service-writeup{
    width: 100%;
    height: 100%;
    order: 2;
  }
  .writpup{
    width: 100%;
    align-content: left;
    padding: 2rem;
  }

    .img-wrapper {
    width: 250px;
    height: 250px;
}

.img {
    width: 220px;
    height: 220px;
    top: 15px;
    left: 75px
    
}
}
</style>
