<template>
  <div>
      <v-dialog v-model="GET_VERIFICATION_DIALOG" max-width="700" max-height="700" persistent>
        <template>
            <v-card class="text-center  card-container">
            <div class="flex heading">
                <img class="mr-4" src="../../../assets/images/pngroyale 1.png" alt="">
                <h3 class="flex flex-center heading-text">National Identification Number Details <span class="mr-4">(Test Mode)</span></h3>
            </div>
            <div class="body">
                <h3>This is a test mode</h3>
                <p>We have provided some test details you can use to test this service. Tap on the button below to prefill the field with test data.</p>
                <div class="check flex">
                    <v-checkbox></v-checkbox>
                    <span style="color: #000">
                        <strong> Test Data </strong> Tap to prefill with a valid data
                    </span>
                </div>
                <v-text-field v-model="val" label="Virtual NIN" outlined></v-text-field>
                <!-- <slot></slot> -->
                <div class="btn-wrap">
                    <v-btn class="btn-validate" @click="validate">Validate</v-btn>
                    <v-btn class="btn-cancle" @click="close" text>Cancel</v-btn>
                </div>
            </div>
        </v-card>
        </template>
    </v-dialog>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import {mapGetters, mapMutations} from 'vuex'
export default {

     data() {
        return {
            val: ''
        }
    },
    methods: {
        ...mapMutations(['TOGGLE_VERIFICATION_DIALOG, TOGGLE_INFOVERIFICATION_DIALOG']),
        validate() {
            if(!this.val) return Swal.fire({icon: 'error', text: 'Please enter value'})
            // this.$emit('validate')
            this.$store.commit('TOGGLE_INFOVERIFICATION_DIALOG');
            this.$store.commit('TOGGLE_VERIFICATION_DIALOG');
            this.val  = '';
        },
        verify(){
        },
        close(){
            this.$store.commit('TOGGLE_VERIFICATION_DIALOG');
        }
    },
    computed:{
        ...mapGetters(['GET_VERIFICATION_DIALOG'])
       
    }
}
</script>

<style scoped>
.heading{
    padding: 1rem;
    background: #F6F7F9;
}
.heading img{
    height: 50px;
    width: 50px
}

.card-container{
    border-radius: 15px !important;
}
.heading-text{
    font-weight: bolder;
    font-size: 20px;

}
.heading-text span{
    color: #E78249;
}
.body{
    padding: 2rem 3rem;
    text-align: left;
}
.check{
    font-size: 17px;
    align-items: center;
    border: 1px dotted blue;
    padding-left: .8rem;
    margin-bottom: 1.5rem;
}
.btn-wrap{
    text-align: center;
}
.btn-validate{
    background: #094C82 !important;
    color: #fff;
    font-weight: bold;
    margin-right: .5rem;
    width: 150px !important;    
    height: 47px !important;
}
.btn-cancle{
   width: 150px !important;    
    height: 47px !important; 
}
</style>