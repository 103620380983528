<template>
  <div class="wrapper">
      <div class="showcase flex flex-center flex-column">
          <h2>Select from the services below to run a demo</h2>
          <div class="search flex flex-center">
              <input placeholder="Search for service" type="text">
              <div class="flex flex-center">
                  <img src="../assets/images/search.png" alt="">
              </div>
          </div>
      </div>

      <div class="verify">
          <v-card class="service-card" v-for="(service, i) in services" :key="i">
              <img src="../assets/images/Group 110.svg" alt="">
              <h4>{{service.name}}</h4>
              <p>
                  {{service.caption}}
              </p>

              <div class="overlay">
                  <v-btn class="service-btn" @click="TOGGLE_VERIFICATION_DIALOG">
                      Test Service
                  </v-btn>
              </div>
          </v-card>
      <ValidationModal/>
      <ValidationInfoModal/>

      </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';
import ValidationModal from '../components/View/Service/VerificationModal.vue';
import ValidationInfoModal from '../components/View/Service/InfoDialogCard.vue';
export default {
    components: {
        ValidationModal,
        ValidationInfoModal
    },
    data(){
        return {
            showModal: false,
            services:[
                {
                    name: 'Validate BVN',
                    caption: 'Verify people using their provided bank verification numbers.'
                },
                {
                    name: 'NIN Verification',
                    caption: 'Verify NIN using your NIN number'
                },
                {
                    name: 'International Passport',
                    caption: 'Verify people using their provided bank verification numbers.'
                },
                {
                    name: 'VIN Verification',
                    caption: 'Verify people using their provided bank verification numbers.'
                },
                {
                    name: 'Bank Account Number',
                    caption: 'Verify people using their provided bank verification numbers.'
                },
                {
                    name: 'Residence Address',
                    caption: 'Verify people using their provided bank verification numbers.'
                },
            ]
        }
    },
    methods:{
        ...mapMutations(['TOGGLE_VERIFICATION_DIALOG'])
    },
    computed:{
        ...mapGetters(['GET_VERIFICATION_DIALOG'])
    }
}
</script>

<style scoped>
.wrapper{
    padding: 3rem;
}
.showcase{
    height: 400px;
    background:  url('../assets/images/Rectangle 12.svg');
    background-size: cover;
    padding: 2rem;
}
.showcase h2{
    font-size: 1.7rem;
    color: #fff;

}
.showcase p{
    color: #fff
}
.search {
    width: 500px;
    background: #fff;
    border-radius: 4px;
    padding: .5rem;

}
.search input{
    font-size: 18px;
    padding: 1rem;
    width: 85%;
    outline: none;
    border: none;
}
.search div{
    width: 15%;
    display: inline-block;
    background: #094C82;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
}
.overlay{
    background: #094C82CF;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: none;
    
}
.service-btn{

}
.verify{
    padding: 2rem 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 50px;
}

.service-card{
    padding: 2rem;
    position: relative;
    border-radius: 10px !important;
    cursor: pointer;
}
.service-card h4{
    color: #094C82;
    font-size: 17px;
}

.service-card p{
    color: rgb(137, 135, 135);
    font-size: 14px;
}


.service-card:hover .overlay{
    transition: ease-in all 1s;
        display: flex;
    align-items: center;
    justify-content: center;
   
}

.service-btn{
    padding: 1.5rem !important;
    width: 150px !important;
    height: 40px !important;
    color: #094C82 !important;
    font-weight: bold;
    font-size: 13px !important;
    background-color: #fff !important;
}

@media (max-width: 900px){
    .verify{
        grid-template-columns: 1fr 1fr;
    }
    .showcase h2{
        font-size: 18px;
        
    }
    .search {
    width: 350px;
    background: #fff;
    border-radius: 4px;
    padding: .5rem;
}
}
@media (max-width: 600px){
    .verify{
        grid-template-columns: 1fr;
    }
    
}

</style>