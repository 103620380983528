<template>
  <div>
    <show-case>
      <div class="content-wrapper">
        <div class="content flex flex-center flex-column">
          <h1>Our Policy & Privacy</h1>
        </div>
      </div>
    </show-case>

   <div style="padding: 20px 40px;">
      <h3>Effective from January 2024</h3>
      <p>
        <strong>1. Introduction </strong> <br>
        OneID Synergy Impex Ltd is committed to protecting the privacy and
        security of your personal information. This privacy policy outlines our
        practices regarding the collection, use, and disclosure of personal
        information through our website and online identity verification
        platform (collectively, the "Platform"), in compliance with the Nigeria
        Data Protection Regulation (NDPR) 2019, Nigeria Data Protection Act
        (NDPA) 2023 and other relevant privacy laws in Nigeria.
      </p>
      <p>
        <strong> 2. Information We Collect</strong> <br>

We collect information that identifies, relates
      to, describes, or is capable of being associated with you ("Personal
      Information"), including but not limited to:

        <ul>
          <li> Names, email addresses, phone numbers, and dates of birth.</li>
          <li>Biometric information for identity
      verification purposes.</li>
          <li>IP addresses, browser types, and access times.</li>

        </ul>
      </p>

      <p>
        <strong> 3. How We Use Your Information</strong> <br>
          Your Personal Information is used for the
      following purposes:
      <ul>
        <li>To provide and improve our identity verification
      services.</li>
        <li>To comply with applicable legal requirements and regulatory
      obligations.</li>
        <li>For customer support and to respond to your inquiries.</li>
        <li>For internal research and development purposes.</li>
      </ul>
      </p>
      <p>
        <strong> 4. Sharing and Disclosure
      of Personal Information</strong> <br>
      We do not sell your Personal Information. We may
      share your information with third parties in the following situations:
        <ul>
          <li>With service providers who assist us in providing our services.</li>
          <li>To
      comply with legal obligations or in the protection of our legal rights.</li>
          <li>In connection with a merger, sale, or asset transfer.</li>
  
        </ul>
      </p>

      <p>
        <strong>5. Data Security </strong> <br>
        implement reasonable security measures to protect your Personal
      Information from unauthorized access, disclosure, alteration, or
      destruction. However, no internet-based platform can be 100% secure, and
      we cannot guarantee the security of your Personal Information.
      </p>
      <p>
        <strong>6.⁠ ⁠Data Retention
</strong> <br>
 We retain your Personal Information only for as long as
      necessary to fulfill the purposes outlined in this privacy policy, unless
      a longer retention period is required or permitted by law. 
      </p>
      <p>
        <strong>7. Your Rights</strong> <br>
        Under the NDPR, NDPA and other applicable laws, you have rights concerning
      your Personal Information, including the right to access, correct, delete,
      or restrict the use of your Personal Information. To exercise these
      rights, please contact us using the information below.
      </p>
      <p>
        <strong>8. Changes to Our</strong> <br>
        Privacy Policy We may update this privacy policy from time to time. The
      effective date at the top of this policy will be updated to reflect any
      changes. We encourage you to review our privacy policy periodically.
       
      </p>

      <p>
        <strong> 9.
      Contact</strong><br>
      Us If you have any questions about this privacy policy or our data
      practices, please contact us at: 
      <ul>
        <li>Email: info@oneid.ng</li>
        <li>Phone:
      09025579505</li>

      </ul>
      </p>

       

    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.content-wrapper {
    background: url('../assets/images/Rectangle 12.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-start;
    font-family: "Metropolis";
    height: 100%;
    width: 100%
}

.content {
    height: 100%;
    color: #fff;
    padding: 2rem;
    text-align: center;

}

.heading {
    padding: 2rem;
    text-align: center;
}

.img-service {
    height: 100%;
    width: 100%;
}

@media (max-width: 1000px) {
    .content-wrapper {
        /* background-size: ; */
    }
}
</style>
