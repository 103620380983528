<template>
<v-app>

    <v-main>
        <router-view />
    </v-main>
</v-app>
</template>

<script>
export default {
    name: 'App',

    data: () => ({
        //
    }),
     mounted() {
    AOS.init()
  },
};
</script>

<style>
html{
    scroll-behavior: smooth;
}
@font-face {
    font-family: 'Metropolis';
    src: url('../src/assets/fonts/metropolis/Metropolis-Regular.otf');
}
*{
  font-family: "Metropolis";
  box-sizing: border-box;
}
body {
    font-family: "Metropolis";
}

.flex {
    display: flex;
}

.flex-center {
    align-items: center;
    justify-content: center;
}

.flex-row {
    flex-direction: row;
}

.flex-column {
    flex-direction: column;
}

.space-btw {
    justify-content: space-between;
    align-items: center;
}

.space-around {
    justify-content: space-around;
    align-items: center;
}

.auth-heading h4 {
    padding: 0px;
    margin: 0px;
    color: #fff;
    font-size: 22px;
    margin-bottom: -9px;
}

.auth-heading span {
    font-size: 11px;
    color: #ccc
}
</style>
