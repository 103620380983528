<template>
<div class="">
    <show-case>
        <div class="content-wrapper">
            <div class="content flex flex-center flex-column">
                <h1>Our Products & Services</h1>
                <p>
                    Let us help make your identity management and verification needs seamless
                </p>
            </div>
        </div>
    </show-case>
    <div class="body-wrap">
        <h3 class="heading">We provide the following services:</h3>
        <div class="">
            <div data-aos="fade-right" data-aos-duration="1000">
                <service-card-right :background="'#D2E0F8'" @handledemo="showdemo">
                    <template slot="heading">
                        Identity verification
                    </template>
                    <template slot="image">
                        <img class="img-service" src="../assets/images/service1.png" alt="">
                    </template>
                    <template>

                        <check-write>
                            <template>
                                Face match verification
                            </template>
                            <template slot="writeup">
                                Ensure that your clients are customers are who they say they are and put the risks of identity fraud and impersonation behind you forever.
                            </template>

                        </check-write>
                        <check-write>
                            <template>
                                Taxpayer Identity
                            </template>
                        </check-write>
                    </template>
                </service-card-right>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000">
                <service-card-left :background="'#DED4AD'" @handledemol="showdemo">
                    <template slot="heading">
                        Business verification
                    </template>
                    <template slot="caption">
                        We will help empower you to easily detect and weed out all fakes and do business with only real businesses.
                    </template>
                    <template slot="image">
                        <img class="img-service" src="../assets/images/service2.png" alt="">
                    </template>
                    <template>

                        <check-write>
                            <template>
                                Verify Company Tax Identification Number
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                RC number
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Business address
                            </template>
                        </check-write>
                    </template>

                </service-card-left>
            </div>
            <div data-aos="fade-right" data-aos-duration="1000">
                <service-card-right :background="'#ADD8C6'" @handledemo="showdemo">
                    <template slot="heading">
                        Document and proof of ID validations
                    </template>
                    <template slot="caption">
                        Helping you make sure the information provided by your customers and clients are authentic and belong to them.
                    </template>
                    <template slot="image">
                        <img class="img-service" src="../assets/images/service3.png" alt="">
                    </template>
                    <template>

                        <check-write>
                            <template>
                                Validate BVN
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                National Identification Number
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Vehicle Identification Number
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                International passports
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Bank account number
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Residence address
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Permanent Home address
                            </template>
                        </check-write>
                    </template>
                </service-card-right>
            </div>
            <div data-aos="fade-left" data-aos-duration="1000">
                <service-card-left :background="'#EBAAA7'" @handledemol="showdemo">
                    <template slot="heading">
                        AI powered services
                    </template>
                    <template slot="caption">
                        KYC compliance made easier.
                    </template>
                    <template slot="image">
                        <img class="img-service" src="../assets/images/service4.png" alt="">
                    </template>
                    <template>

                        <check-write>
                            <template>
                                Liveness detection
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Anti-spoofing
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Type of ID card recognition
                            </template>
                        </check-write>
                    </template>

                </service-card-left>
            </div>

            <div data-aos="fade-right" data-aos-duration="1000">
                <service-card-right :background="'#DED9DF'" @handledemo="showdemo">
                    <template slot="heading">
                        KYC onboarding
                    </template>
                    <template slot="caption">
                        Optimize business growth and turnaround time with our safe and fast platform.
                    </template>
                    <template slot="image">
                        <img class="img-service" src="../assets/images/service5.png" alt="">
                    </template>
                    <template>

                        <check-write>
                            <template>
                                Compliance checks
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                                Easy verification
                            </template>
                        </check-write>
                        <check-write>
                            <template>
                               Optimize onboarding of your client using our OneID api.
                            </template>
                        </check-write>
                    </template>
                </service-card-right>
            </div>

        </div>
    </div>
</div>
</template>

<script>
import CheckWrite from '../components/View/Service/CheckWriteup.vue'
import ShowCase from '../components/View/ShowCase.vue';
import ServiceCardRight from '../components/View/Service/ServiceCardRight.vue'
import ServiceCardLeft from '../components/View/Service/ServiceCardLeft.vue'
export default {
    components: {
        CheckWrite,
        ShowCase,
        ServiceCardLeft,
        ServiceCardRight
    },
    methods:{
        showdemo(){
        this.$router.push('/service-demo');
        console.log('Demo 2')
    }
    }

}
</script>

<style scoped>
.wapper {
    background-color: #F9F9F9;
}

.content-wrapper {
    background: url('../assets/images/Rectangle 12.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    justify-content: flex-start;
    font-family: "Metropolis";
    height: 100%;
    width: 100%
}

.content {
    height: 100%;
    color: #fff;
    padding: 2rem;
    text-align: center;

}

.heading {
    padding: 2rem;
    text-align: center;
}

.img-service {
    height: 100%;
    width: 100%;
}

@media (max-width: 1000px) {
    .content-wrapper {
        /* background-size: ; */
    }
}
</style>
