<template>
<div class="mb-2 ">
    <div class="wraper flex">
        <span>
            <img src="../../../assets/images/Vectorp.svg" alt="">
        </span>
        <strong>
            <slot></slot>
        </strong>
    </div>
    <div class="caption">
        <slot name="writeup"></slot>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
.wraper {
    font-size: 15px;
    height: 20px;

    /* padding: .2rem; */
    /* text-align: left; */

}

.wraper strong {
    /* width: 80%; */
    margin-left: .5rem;
    font-weight: bolder;
    color: #094C82;
}

.caption {
    font-size: 13px;
    margin-left: 1.4rem;
}
</style>
